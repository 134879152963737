import * as React from 'react'

import Avatar from '../../shared/Avatar'
import Card from '../../shared/Card'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import getSocialIcons from '../../shared/Helpers/getSocialIcons'
import { Image } from '../../shared/Image'
import Link from '../../shared/Link'
import {
  Caption,
  Description,
  SubTitle,
  Tag,
  Text,
  Title,
} from '../../shared/Typography'
import styled from '../../styled'
import { IFormattedProp, IListTeam } from './types'

interface IProps {
  data: IFormattedProp
}

const CardJobs = styled(Card)`
  background-color: #fafafa;
  border: 1px solid rgba(83, 98, 124, 0.1);
  text-align: center;
  display: flex;

  > div {
    margin: auto;
  }

  .gatsby-image-wrapper {
    display: inline-block;
    width: 100%;
    max-width: 9em;
    margin-bottom: 1em;
  }
`

const GridTeam = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-between;

  &:after {
    content: '';
    width: calc((100% / 3) - 0.5em);
  }
`

const COLUMN_WIDTH = [
  '100%',
  'calc((100% / 2) - .5em)',
  'calc((100% / 3) - .5em)',
]

const TeamAbout: React.FC<IProps> = ({ data: { team, advisors } }) => {
  return (
    <Box>
      <Container pt={['0', '5em']} mb={['5em', '5em']}>
        <Box pb={['5em', '7.5em']}>
          <Box mb="2.5em">
            <Title textAlign="center" mb="1rem" id="team">
              {team.title}
            </Title>
          </Box>
          <GridTeam>
            {team.list.map(renderTeamItem)}
            <OpeningJobs />
          </GridTeam>
        </Box>

        <Flex flexWrap="wrap">
          <Flex width={['100%', null, '42%']}>
            <Box m="auto">
              <SubTitle pr="1em" mb="1rem">
                {advisors.title}
              </SubTitle>
              <Description color="grey" mb="1rem">
                {advisors.description}
              </Description>
            </Box>
          </Flex>

          <Box
            width={['100%', null, '54%']}
            pt={['0', '2em']}
            pl={['0', '2em', '2em']}
          >
            {advisors.list.map(renderAdvisorItem)}
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

const OpeningJobs: React.FC = () => (
  <CardJobs dark width={COLUMN_WIDTH} mb="1em" p="1em">
    <div>
      <Image path="notFoundHero" alt="Jobs openings" />
      <Caption>You?</Caption>
      <Link color="blue" to="/careers" withIcon="arrow">
        Jobs openings
      </Link>
    </div>
  </CardJobs>
)

const renderTeamItem: React.FC<IListTeam> = ({
  name,
  image,
  social,
  description,
}) => {
  const divided = description.split('\n')

  return (
    <Card key={name} width={COLUMN_WIDTH} mb="1em" p="1em">
      <Flex alignItems="center" flexWrap="wrap">
        <Flex>
          <Avatar size="5em">
            <Image path={image} alt={name} />
          </Avatar>
          <Flex>
            <Box m="auto">
              <Caption ml="1rem">{name}</Caption>
              <Box ml="1rem">
                {Array.isArray(social) && social.sort().map(renderIcons)}
              </Box>
            </Box>
          </Flex>
        </Flex>
        <Box minHeight="92px" width="100%">
          {divided.map((item, indexDivided) => (
            <Text mt="1em" color="grey" key={`${item}-${indexDivided}`}>
              {item}
            </Text>
          ))}
        </Box>
      </Flex>
    </Card>
  )
}

const renderIcons: React.FC<string> = social => {
  const domain = social.match(/^(?:.*:\/\/)?(?:.*?\.)?([^:/]*?\.[^:/]*).*$/)
  if (Array.isArray(domain)) {
    const rootDomain = domain[1].split('.')[0]

    return (
      <Link ghost to={social} childrenProps={{ mr: '.5em' }}>
        <Text color="grey">{getSocialIcons(rootDomain)}</Text>
      </Link>
    )
  }

  return null
}

const renderAdvisorItem: React.FC<IListTeam> = (
  { name, image, description }: IListTeam,
  index: number
) => {
  const margins = [5, 10]
  const divided = description.split('\n')

  return (
    <Card
      p="1em"
      mb="1.5em"
      key={name}
      width={['100%', '80%']}
      ml={[0, `${margins[index]}em`]}
    >
      <Flex alignItems="center" flexWrap="wrap">
        <Avatar size="2.5em">
          <Image path={image} alt={name} />
        </Avatar>
        <Box ml="1rem">
          <Caption>{name}</Caption>
          <Text color="grey">Advisor</Text>
        </Box>
        {divided.map((item, indexDivided) => (
          <Text mt="1em" color="grey" key={`${item}-${indexDivided}`}>
            {item}
          </Text>
        ))}
      </Flex>
    </Card>
  )
}

export default TeamAbout
