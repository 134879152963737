import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Description, MainTitle } from '../../shared/Typography'
import { IHero } from './types'

interface IProps {
  data: IHero
}

export default function renderHeroHomepage({ data }: IProps) {
  return (
    <Container>
      <Flex
        alignItems="center"
        justifyContent="center"
        mt="4rem"
        mb={['4em', '5em']}
      >
        <Box maxWidth="550px">
          <MainTitle textAlign="center" mb="1rem">
            {data.title}
          </MainTitle>
          <Description textAlign="center" mb="1rem" color="greyMedium">
            {data.description}
          </Description>
        </Box>
      </Flex>
    </Container>
  )
}
