import { TextLink } from '../../shared/Typography'
import styled, { css } from '../../styled'

export const Tab = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    position: relative;

    &:after {
      content: '';
      display: block;
      border-radius: 2px;
      width: 2px;
      height: 100%;
      background-color: ${colors.grayscale.light};
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }

    @media screen and (max-width: ${breakpoints[0]}) {
      display: flex;
      white-space: nowrap;
      overflow: auto;
      margin-bottom: 2em;
      border-bottom: 1px solid ${colors.grayscale.light};
      -webkit-overflow-scrolling: touch;

      &:after {
        display: none;
      }
    }
  `};
`

export const TabItem = styled(TextLink)<{ active: boolean }>`
  ${({ active, theme: { colors, transition, breakpoints } }) => css`
    padding-left: 1rem;
    position: relative;
    transition: color ${transition.ease()};
    color: ${colors.grayscale.medium};
    ${active &&
      css`
        color: ${colors.blue.default};
      `};

    &:after {
      transition: background ${transition.ease()};
      content: '';
      display: block;
      border-radius: 2px;
      width: 2px;
      height: 100%;
      background-color: transparent;
      position: absolute;
      left: 0;
      top: -2px;
      bottom: 0;
      z-index: 2;
      ${active &&
        css`
          background-color: ${colors.blue.default};
        `};

      @media screen and (max-width: ${breakpoints[0]}) {
        top: auto;
        left: 0.2em;
        right: -0.5em;
        height: 2px;
        width: auto;
        bottom: -11px;
      }
    }
  `};
`
