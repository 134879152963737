import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../../utils/mergeEdges'
import { IFormattedProp, IProp } from './types'
import View from './view'

export default function queryTeamAbout() {
  return (
    <StaticQuery
      query={graphql`
        query TeamAbout {
          allFoundationYaml {
            edges {
              node {
                about {
                  team {
                    title
                    list {
                      name
                      description
                      image
                      social
                    }
                  }
                  advisors {
                    title
                    description
                    list {
                      name
                      description
                      image
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allFoundationYaml }: IProp) => {
        const data = mergeEdges(allFoundationYaml)

        if (!data) {
          return null
        }

        return <View data={data.about} />
      }}
    />
  )
}
