import * as React from 'react'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Image } from '../../shared/Image'
import Parallax from '../../shared/Parallax'
import { Description, Title } from '../../shared/Typography'
import { ISection } from './types'

interface IProps {
  data: ISection
}

export default function renderSectionAbout({ data }: IProps) {
  const padd = ['0', '6em']

  return (
    <Container>
      <Flex
        alignItems="center"
        mb={['7.5em', '7em']}
        mt={['2em', '5em']}
        flexWrap="wrap"
      >
        <Box width={['100%', null, '55%']} pr={padd} mb="2.5em">
          <Title mb="1rem">{data.section.title}</Title>
          <Description mb="1rem" color="greyMedium">
            {data.section.description}
          </Description>
        </Box>

        <Box width={['100%', null, '45%']}>
          <Parallax force={1.2} offsetComp={30}>
            {(offSet: number) => (
              <Image
                path={data.section.image}
                alt={data.section.title}
                style={{
                  transform: `translateY(${offSet}px) translateZ(0)`,
                }}
              />
            )}
          </Parallax>
        </Box>
      </Flex>
    </Container>
  )
}
