import * as React from 'react'

import Career from '../components/About/Career'
import Hero from '../components/About/Hero'
import Section from '../components/About/Section'
import Tabs from '../components/About/Tabs'
import Team from '../components/About/Team'
import Values from '../components/About/Values'
import SEO from '../components/SEO'
import Layout from '../layouts'

const AboutPage: React.FC = () => {
  return (
    <Layout>
      <SEO />
      <Hero />
      <Section />
      <Tabs />
      <Values />
      <Team />
      <Career />
    </Layout>
  )
}

export default AboutPage
