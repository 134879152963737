import styled from '../../styled'

export const FadeTransition = styled.div`
  .fade-enter {
    opacity: 0.01;
    transform: translateY(-2em);
    line-height: 4;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity ${({ theme: { transition } }) => transition.ease()},
      transform ${({ theme: { transition } }) => transition.cubic(700)};
    transform: translateY(0);
  }

  .fade-exit {
    transition: opacity ${({ theme: { transition } }) => transition.ease()},
      transform ${({ theme: { transition } }) => transition.cubic(700)};
    transform: translateY(0em);
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
  }

  .fade-exit-active {
    opacity: 0.01;
    transform: translateY(1em);
  }
`
