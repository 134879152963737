import * as React from 'react'

import Card from '../../shared/Card'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { ImageRounded } from '../../shared/Image'
import Link from '../../shared/Link'
import { Caption, Text } from '../../shared/Typography'
import { ImageTexture } from './parts'
import { ICareer } from './types'

interface IProps {
  data: ICareer
}

export default function renderCareerAbout({ data: { career: data } }: IProps) {
  const ContentCard = () => (
    <Card p={[null, '2em']} ml={['0', '2em']} mt={['0', '4em']}>
      <Caption mb="1rem">{data.title}</Caption>
      <Text mb="1.5rem" color="grey">
        {data.description}
      </Text>
      {data.ctaLink && (
        <Link color="blue" to={data.ctaLink} withIcon="arrow">
          {data.cta}
        </Link>
      )}
    </Card>
  )

  return (
    <Container mb={['5em', '12em']}>
      <Flex alignItems="center" flexWrap="wrap">
        <Box width={['100%', '50%']} pr={['0', '1.5em']} mb="1.5em" zIndex={2}>
          <ContentCard />
        </Box>

        <Box width={['100%', '50%']}>
          <Box
            width={['100%', 'calc(100% + 8em)']}
            ml={['0', '-8em']}
            mb="1.5em"
          >
            <ImageRounded path={data.images[0]} alt={data.title} />
          </Box>
          <Box width={['100%', '264px']}>
            <ImageRounded path={data.images[1]} alt={data.title} />
          </Box>
        </Box>

        <ImageTexture>
          <ImageRounded path="aboutTeamTexture" alt={data.title} />
        </ImageTexture>
      </Flex>
    </Container>
  )
}
