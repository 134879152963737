import * as React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Tab, TabItem } from '../../shared/Tabs'
import { Description } from '../../shared/Typography'
import { FadeTransition } from './parts'
import { ITab, ITabContent } from './types'

interface IState {
  selected: number
}

interface IProps {
  data: ITab
}

export default class RenderTabAbout extends React.Component<IProps, IState> {
  state = { selected: 0 }

  handleClick = (index: number) => {
    this.setState({ selected: index })
  }

  renderTitles = ({ title }: ITabContent, index: number) => {
    const active = this.state.selected === index

    return (
      <Box key={title}>
        <button
          aria-label={title}
          role="presentation"
          onClick={() => this.handleClick(index)}
        >
          <TabItem mb=".7rem" mt=".7rem" active={active}>
            {title}
          </TabItem>
        </button>
      </Box>
    )
  }

  renderDescription = ({ description, title }: ITabContent, index: number) => {
    if (index === this.state.selected) {
      const splited = description.split('\n')
      return (
        <CSSTransition
          key={`${title}-${index}`}
          timeout={500}
          classNames="fade"
        >
          <div>
            {splited.map((item, indexSplited) => (
              <Description
                mb="1em"
                key={`${title}-${indexSplited}`}
                color="greyMedium"
              >
                {item}
              </Description>
            ))}
          </div>
        </CSSTransition>
      )
    }

    return null
  }

  render() {
    const {
      data: { tabs },
    } = this.props

    return (
      <Container px={[null, '0', '6em']} mb={['5em', '12em']}>
        <Flex flexWrap="wrap">
          <Box width={['100%', '30%']}>
            <Tab>{tabs.map(this.renderTitles)}</Tab>
          </Box>

          <Box width={['100%', '70%']}>
            <FadeTransition>
              <TransitionGroup>
                {tabs.map(this.renderDescription)}
              </TransitionGroup>
            </FadeTransition>
          </Box>
        </Flex>
      </Container>
    )
  }
}
