import styled, { css } from '../../styled'

import { IProp } from './types'

export default styled.div<IProp>`
  ${({ size }) => css`
    width: ${size ? size : '3.6em'};
    max-width: ${size ? size : '3.6em'};
    min-width: ${size ? size : '3.6em'};
    height: ${size ? size : '3.6em'};
    max-height: ${size ? size : '3.6em'};
    min-height: ${size ? size : '3.6em'};
  `};

  border-radius: 100%;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`
