import * as React from 'react'

import Card from '../../shared/Card'
import { Container } from '../../shared/Grid'
import { Flex } from '../../shared/Helpers'
import { Caption, Description, Text, Title } from '../../shared/Typography'
import styled, { css } from '../../styled'
import { IList, IValues } from './types'

interface IProps {
  data: IValues
}

const FlexColumn = styled(Flex)`
  flex-flow: row wrap;
  justify-content: space-between;

  &:after {
    content: '';

    ${({ theme: { breakpoints } }) => css`
      @media screen and (min-width: ${breakpoints[0]}) {
        display: none;
      }

      @media screen and (min-width: ${breakpoints[1]}) {
        display: block;
        width: calc((100% / 2) - 0.7em);
      }

      @media screen and (min-width: ${breakpoints[1]}) {
        display: block;
        width: calc((100% / 3) - 0.7em);
      }
    `}
  }
`

export default function renderValuesAbout({ data: { values } }: IProps) {
  return (
    <Container zIndex={3}>
      <Title textAlign="center" mb="1rem" id="values">
        {values.title}
      </Title>
      <Description textAlign="center" mb="1rem" color="greyMedium">
        {values.description}
      </Description>

      <FlexColumn mt={['2.5rem', '4em']} mb={'4em'}>
        {values.list.map(renderCards)}
      </FlexColumn>
    </Container>
  )
}

function renderCards({ title, description }: IList) {
  const width = ['100%', 'calc((100% / 2) - .7em)', 'calc((100% / 3) - .7em)']
  return (
    <Card mb="1.5em" width={width} key={title}>
      <Caption mb="1rem">{title}</Caption>
      <Text color="grey">{description}</Text>
    </Card>
  )
}
