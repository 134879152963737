import styled, { css } from '../../styled'

export const ImageTexture = styled.div`
  ${({ theme: { breakpoints } }) =>
    css`
      position: absolute;
      right: -4em;
      left: -4em;
      bottom: -4em;
      z-index: -2;
      max-width: none;
      width: calc(100% + 8em);

      @media screen and (max-width: ${breakpoints[0]}) {
        display: none;
      }
    `}
`
